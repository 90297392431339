// ############################################################### FLAG LABELS WHEN THERE ARE FIELDS WITH ERROR
/*
 * For highlighting invalid fields on forms that have an `is-invalid` rendered from the server,
 * we want to add an additional `has-error` class to the labels for Bootstrap usage"
 */
const fieldWithErrorsElements = document.querySelectorAll('.is-invalid');

fieldWithErrorsElements.forEach(function (element) {
  const label = element.parentElement.querySelector('label')

  if (label) {
    label.classList.add('has-error')
  }
});


// ############################################################### CLICKABLE TABLE ROWS
const rowClickableElements = document.querySelectorAll('.row-clickable');

rowClickableElements.forEach(function (element) {
  element.addEventListener('click', function (event) {
    const href = this.querySelector('a');

    if (href) {
      window.location = href;
    }
  })
});


// ############################################################### POLYFILL
/*
 * This polyfill adds support to IE (all versions) so that we can use a standard .forEach iterator
 */
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window;

    for (let i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}
