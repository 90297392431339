/*
 * Include in this file references to any libraries or general application specific code that's
 * applicable to multiple areas of the application.
 *
 * Examples would be date time pickers, standard controls, bootstrap js customizations, etc.
 *
 * jQuery is automatically picked up here due to bootstrap requirements
 */

// Libraries
// From vendor/assets/javascript
import 'account_sites/bootstrap.bundle.min';

// Application specific
import '../../js/account_sites/common.es6'
